<template>
  <div
    v-if="!mobile"
    class="d-flex"
  >
    <div v-for="item in items">
      <v-btn
        size="small"
        :class="item === activeItem ? 'text-secondary' : ''"
        @click="navigateTo(`/${item.name}`)"
      >
        {{ t(`btn.${item.name}`) }}
      </v-btn>
    </div>
  </div>

  <div
    v-if="mobile"
    class="d-flex w-100 px-2"
  >
    <div v-for="item in items" class="width text-center">
      <v-btn
        size="small"
        color="primary"
        variant="flat"
        :class="item === activeItem ? 'text-secondary' : ''"
        @click="navigateTo(`/${item.name}`)"
      >
        {{ t(`btn.${item.name}`) }}
      </v-btn>
    </div>
  </div>
</template>

  <script
    setup
    lang="ts"
  >

import { useDisplay } from 'vuetify'

const {t} = useI18n()
const route = useRoute()
const {mobile} = useDisplay()

const items = [
  {name: 'tasks'},
  {name: 'users'},
  {name: 'statistics'}
]

const activeItem = computed(() => {
  if (route.fullPath === '/') {
    return {name: 'tasks'};
  }

  return items.find((item) => {
    return route.fullPath.toString().includes(`/${item.name}`);
  });
});
</script>

<style scoped>
.width {
  width: 33.3%;
}
</style>

